import React from 'react';
import { Chart, ChartProps } from '../../components/Chart';
import { getMonthNameLabels } from '../../components/Chart/utils';

export const chartData: ChartProps = {
  type: 'bar',
  options: {
    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
        },
        border: {
          display: false
        }
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => {
            return `${value}k`;
          },
          color: '#6F7780',
          font: {
              family: 'Nunito Sans',
              size: 11,
              weight: 'bold',
          },
        },
        border: {
          display: false
        },
        title: {
          display: true,
          text: 'REVENUE',
          color: '#6F7780',
          font: {
              family: 'Nunito Sans',
              size: 11,
              weight: 'bold',
          },
          padding: {
            bottom: 10,
          },
        },
      },
    },
    plugins: {
      tooltip: {
          callbacks: {
              label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                      label += ': ';
                  }
                  if (context.parsed.y !== null) {
                      label += (context.parsed.y)+'K';
                  }
                  return label;
              }
          },
          xAlign: 'center',
          yAlign: 'bottom',
          titleMarginBottom: 1,
          titleFont: {
              family: 'Nunito Sans',
              weight: 'bold',
              size: 11,
          },
          titleAlign: 'left',
          backgroundColor: '#fff',
          titleColor: '#6F7780',
          bodyColor: '#6F7780',
          bodyFont: {
              family: 'Nunito Sans',
              weight: '700',
              size: 11
          },
          usePointStyle: true,
          boxPadding: 3,
      },
    },
  },
  data: {
    labels: getMonthNameLabels(),
    datasets: [
      {
        label: 'White beach cottage',
        data: [12, 26, 8, 11, 26, 7.5, 12.5, 25.5, 17, 14.9, 26, 10],
        backgroundColor: '#4693F7',
        maxBarThickness: 25,
        borderRadius: 3,
      },
    ],
  },
};

export default function RevenueChart(){
  return  (<Chart {...chartData} />);
}