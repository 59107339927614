import React from 'react';
import { Avatar, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import WarningIcon from '@mui/icons-material/Warning';

import './CreateInvoicesPopup.scss';
import imgOwner1 from '../../../../assets/images/placeholder/user-1.png'
import imgOwner2 from '../../../../assets/images/placeholder/user-2.png'
import imgOwner3 from '../../../../assets/images/placeholder/user-3.png'
import imgOwner4 from '../../../../assets/images/placeholder/user-4.png'
import imgOwner5 from '../../../../assets/images/placeholder/user-5.png'
import imgRental1 from '../../../../assets/images/placeholder/thumb-1.jpg'
import imgRental2 from '../../../../assets/images/placeholder/thumb-2.jpg'
import imgRental3 from '../../../../assets/images/placeholder/thumb-3.jpg'
import imgRental4 from '../../../../assets/images/placeholder/thumb-4.jpg'
import imgRental5 from '../../../../assets/images/placeholder/thumb-5.jpg'
import imgAirbnb from '../../../../assets/images/channels/airbnb.png'
import imgAgoda from '../../../../assets/images/channels/agoda.png'
import imgBookingCom from '../../../../assets/images/channels/booking_com.png'
import imgExpedia from '../../../../assets/images/channels/expedia.png'
import imgHolidu from '../../../../assets/images/channels/holidu.png'
import imgHometogo from '../../../../assets/images/channels/hometogo.png'
import imgWebready from '../../../../assets/images/channels/webready.png'

const ITEM_HEIGHT = 34;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      maxWidth: 250
    },
  },
};

export type rentalListType = {
    name: string,
    rentalImg: string,
}
const rentals: rentalListType[] = [
    {name: 'Nest Villas', rentalImg: imgRental4},
    {name: 'The Like View Guesthouse', rentalImg: imgRental1},
    {name: 'Koon Cha Learn', rentalImg: imgRental2},
    {name: 'Bunk Boutique Hostel Bunk Boutique Hostel', rentalImg: imgRental3},
    {name: 'Pong Phen Guesthouse', rentalImg: imgRental4},
    {name: 'ETZzz Hostel Bangkok Bunk Boutique Hostel', rentalImg: imgRental5},
    {name: 'Drimba Villa', rentalImg: imgRental2},
    {name: 'Goomback Griss Villa', rentalImg: imgRental4},
    {name: 'Lim Gonas Groos Bunk Boutique Hostel', rentalImg: imgRental1},
    {name: 'Afrt Vascot', rentalImg: imgRental3},
    {name: 'Lom Cha Learn', rentalImg: imgRental5}
];

export type channelListType = {
    name: string,
    rentalImg: string,
}
const channels: channelListType[] = [
    {name: 'Agoda', rentalImg: imgAgoda},
    {name: 'Expedia', rentalImg: imgExpedia},
    {name: 'Airbnb', rentalImg: imgAirbnb},
    {name: 'Hometogo', rentalImg: imgHometogo},
    {name: 'Booking.com', rentalImg: imgBookingCom},
    {name: 'Holidu', rentalImg: imgHolidu},
    {name: 'Webready', rentalImg: imgWebready}
];

export default function CreateInvoicesPopup(props: any) {
    const [ownerName, setOwnerName] = React.useState<string[]>([]);
    const handleOwnerListChange = (event: SelectChangeEvent<typeof ownerName>) => {
        const {
        target: { value },
        } = event;
        setOwnerName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(', ') : value,
        );
    };

    const [rentalName, setRentalName] = React.useState<any[]>([]);
    const handleRentalListChange = (event: SelectChangeEvent<typeof rentalName>) => {
        const {
        target: { value },
        } = event;
        setRentalName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
    };
    const [channelName, setChannelName] = React.useState<any[]>([]);
    const handleChannelListChange = (event: SelectChangeEvent<typeof rentalName>) => {
        const {
        target: { value },
        } = event;
        setChannelName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
    };
    // delee chip data is not working. need to fix it //
    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

  return (
    <Dialog
        maxWidth='sm'
        fullWidth
        className="create_invoices_popup"
        open={props.open}
        onClose = {(event, reason) => {
            if (props.onClose) {
                props.onClose(event, reason);
            }
        }}
      >
        <DialogTitle className="dialog_title_box">
            <div className="title_holder">
                <h4>Create Invoices</h4>
            </div>
            <IconButton onClick={props.onClose} className="close_btn">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content_box">
            <h6>
                INVOICE FILTERS
                <Tooltip title="This invoice filters limits the scope of bookings included in the invoice." placement="right" arrow>
                    <HelpOutlineIcon />
                </Tooltip>
            </h6>
            <div className="row">
                <div className="col-md-6 input_box">
                    <label>Create invoice for this owners <span className="text-danger">*</span></label>
                    <FormControl className="mui-dflt-select-box has_img" fullWidth>
                        <Select
                            defaultValue={['owner_1',]}
                            multiple
                            onChange={handleOwnerListChange}
                            MenuProps={MenuProps}
                            required
                        >
                            <div className="search_box_under_select">
                                <TextField 
                                    variant="outlined"
                                    placeholder="Search" 
                                    fullWidth
                                    type="search" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_l_icon search-holder" 
                                    InputProps={{
                                        startAdornment: 
                                        <InputAdornment position="start">
                                            <IconButton size="small">
                                                <SearchOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                            <MenuItem className='d_flx_img_menu_item' value={'owner_1'}>
                                <Avatar sx={{ bgcolor: '#D65E51', width: 24, height: 24 }}>RC</Avatar> 
                                <span>Ruben Clark</span>
                            </MenuItem> 
                            <MenuItem className='d_flx_img_menu_item' value={'owner_2'}>
                                <Avatar
                                    alt=''
                                    src={imgOwner1}
                                    sx={{ width: 24, height: 24 }}
                                />
                                <span>Araminta Mark</span>
                            </MenuItem>
                            <MenuItem className='d_flx_img_menu_item' value={'owner_3'}>
                                <Avatar sx={{ bgcolor: '#D65E51', width: 24, height: 24 }}>KZ</Avatar> 
                                <span></span>Karim Zenet
                            </MenuItem> 
                            <MenuItem className='d_flx_img_menu_item' value={'owner_4'}>
                                <Avatar
                                    alt=''
                                    src={imgOwner2}
                                    sx={{ width: 24, height: 24 }}
                                />
                                <span>Rabin Bork</span>
                            </MenuItem>
                            <MenuItem className='d_flx_img_menu_item' value={'owner_5'}>
                                <Avatar
                                    alt=''
                                    src={imgOwner3}
                                    sx={{ width: 24, height: 24 }}
                                />
                                <span>Manik Roy</span>
                            </MenuItem>
                            <MenuItem className='d_flx_img_menu_item' value={'owner_6'}>
                                <Avatar
                                    alt=''
                                    src={imgOwner4}
                                    sx={{ width: 24, height: 24 }}
                                />
                                <span>Tomas Crine</span>
                            </MenuItem>

                            <MenuItem className='d_flx_img_menu_item' value={'owner_6'}>
                                <Avatar
                                    alt=''
                                    src={imgOwner5}
                                    sx={{ width: 24, height: 24 }}
                                />
                                <span>Bollal Shah</span>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-6 input_box">
                    <label>During this Month <span className="text-danger">*</span></label>
                    <FormControl className="mui-dflt-select-box" fullWidth>
                        <Select
                            defaultValue={'Sep 2022'}
                            MenuProps={MenuProps}
                            required
                        >
                            <div className="search_box_under_select">
                                <TextField 
                                    variant="outlined"
                                    placeholder="Search" 
                                    fullWidth
                                    type="search" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_l_icon search-holder" 
                                    InputProps={{
                                        startAdornment: 
                                        <InputAdornment position="start">
                                            <IconButton size="small">
                                                <SearchOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                            <MenuItem value={'Sep 2022'}>
                                Sep 2022
                            </MenuItem> 
                            <MenuItem value={'Oct 2022'}>
                                Oct 2022
                            </MenuItem> 
                            <MenuItem value={'Nov 2022'}>
                                Nov 2022
                            </MenuItem> 
                            <MenuItem value={'Dec 2022'}>
                                Dec 2022
                            </MenuItem> 
                            <MenuItem value={'Jan 2023'}>
                                Jan 2023
                            </MenuItem> 
                            <MenuItem value={'Feb 2023'}>
                                Feb 2023
                            </MenuItem> 
                            <MenuItem value={'Mar 2023'}>
                                Mar 2023
                            </MenuItem> 
                            <MenuItem value={'Apr 2023'}>
                                Apr 2023
                            </MenuItem> 
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-6 input_box">
                    <label>Limit to these rental</label>
                    <FormControl className="mui-dflt-select-box has_img" fullWidth>
                        <Select
                            multiple
                            value={rentalName}
                            onChange={handleRentalListChange}
                            MenuProps={MenuProps}
                            renderValue={(selected) => (
                                <div className="chip_holder">
                                    <div className="overflow_touch">
                                        {selected.map((value) => (
                                            <Chip 
                                                variant="outlined"
                                                avatar={
                                                    <Avatar
                                                        alt=''
                                                        // dynamic value is not working for image. need to fix it.
                                                        src={imgRental3}
                                                        sx={{ width: 20, height: 20 }}
                                                    />
                                                } 
                                                key={value} label={value} 
                                                onDelete={handleDelete}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        >
                            <div className="search_box_under_select">
                                <TextField 
                                    variant="outlined"
                                    placeholder="Search" 
                                    fullWidth
                                    type="search" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_l_icon search-holder" 
                                    InputProps={{
                                        startAdornment: 
                                        <InputAdornment position="start">
                                            <IconButton size="small">
                                                <SearchOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                            {rentals.map((rental) => (
                                <MenuItem key={rental.name} value={rental.name} className='d_flx_img_menu_item' divider>
                                    <Checkbox checked={rentalName.indexOf(rental.name) > -1} />
                                    <Avatar
                                        alt=''
                                        src={rental.rentalImg}
                                        sx={{ width: 24, height: 24 }}
                                    />
                                    <span>{rental.name}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-6 input_box">
                    <label>Limit to this channel</label>
                    <FormControl className="mui-dflt-select-box has_img" fullWidth>
                        <Select
                            multiple
                            value={channelName}
                            onChange={handleChannelListChange}
                            MenuProps={MenuProps}
                            renderValue={(selected) => (
                                <div className="chip_holder">
                                    <div className="overflow_touch">
                                        {selected.map((value) => (
                                            <Chip 
                                                variant="outlined"
                                                avatar={
                                                    <Avatar
                                                        alt=''
                                                        // dynamic value is not working for image. need to fix it.
                                                        src={imgExpedia}
                                                        sx={{ width: 20, height: 20 }}
                                                    />
                                                } 
                                                key={value} label={value} 
                                                onDelete={handleDelete}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        >
                            <div className="search_box_under_select">
                                <TextField 
                                    variant="outlined"
                                    placeholder="Search" 
                                    fullWidth
                                    type="search" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_l_icon search-holder" 
                                    InputProps={{
                                        startAdornment: 
                                        <InputAdornment position="start">
                                            <IconButton size="small">
                                                <SearchOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                            {channels.map((channel) => (
                                <MenuItem key={channel.name} value={channel.name} className='d_flx_img_menu_item' divider>
                                    <Checkbox checked={channelName.indexOf(channel.name) > -1} />
                                    <Avatar
                                        alt=''
                                        src={channel.rentalImg}
                                        sx={{ width: 24, height: 24 }}
                                        variant="square"
                                    />
                                    <span>{channel.name}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-12 input_box">
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Include Canceled Bookings" />
                </div>
            </div>
            <h6>
                INVOICE MESSAGE
                <Tooltip title="This invoice message is displayed on the invoice for the owners to view." placement="right" arrow>
                    <HelpOutlineIcon />
                </Tooltip>
            </h6>
            <div className="input_box">
                <label>Message</label>
                <TextField 
                    variant="outlined"
                    multiline
                    fullWidth
                    placeholder="" 
                    type="text" 
                    size="small"
                    rows={5}
                    className="mui_dflt_outlined_input text_area"
                />
            </div>
            <div className="alert_box">
                <WarningIcon />
                <p>
                    If you do not select a rental then this rule will apply to all rentals. The same holds for channels. These values are all optional.
                </p>
            </div>
        </DialogContent>
        <DialogActions className="dialog_footer_box">
            <div className="row justify-content-end">
                <div className="col-auto flx-box">
                    <Button variant='outlined' className="btn_mui_light_blue text-uppercase" onClick={props.onClose}>Cancel</Button>
                    <Button
                        className="btn_mui_primary text-uppercase no_shadow"
                        variant="contained"
                    >
                        CREATE
                    </Button>
                </div>
            </div>
        </DialogActions>
    </Dialog>
  );
}