import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Badge, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';

import './OwnerApprovalPage.scss';
import imageProfile from '../../../assets/images/placeholder/profile.jpeg';
import imgUser1 from '../../../assets/images/placeholder/user-1.png';
import imgUser2 from '../../../assets/images/placeholder/user-2.png';
import imgUser3 from '../../../assets/images/placeholder/user-3.png';
import imgUser4 from '../../../assets/images/placeholder/user-4.png';
import imgUser5 from '../../../assets/images/placeholder/user-5.png';
import imgUser6 from '../../../assets/images/placeholder/user-6.png';
import imgUser7 from '../../../assets/images/placeholder/user-7.png';
import imgUser8 from '../../../assets/images/placeholder/user-8.png';

function OwnerApprovalPage(){

  return (
    <div className="owner-approval-page page_holder">
        <div className="header sticky-top has_searh_box">
            <TextField 
                variant="outlined"
                placeholder="Search for Inquries, Invoices & Guests.." 
                type="search" 
                size="small"
                className="mui_dflt_outlined_input has_l_icon search_header"
                InputProps={{
                    startAdornment: 
                    <InputAdornment position="start">
                        <IconButton size="small">
                            <SearchOutlinedIcon />
                        </IconButton>
                    </InputAdornment>,
                }}
            />
        </div>
        
        <div className="content_holder">
            <div className="account_sidebar_menus">
                <div className="profile_box">
                    <div className="profile">
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <IconButton className="edit_profile" size="small">
                                    <ModeEditOutlineOutlinedIcon sx={{ fontSize: 16, color: '#BDC4CB' }} />
                                </IconButton>
                            }
                        >
                            <Avatar src={imageProfile} alt="" sx={{ width: 75, height: 75 }} />
                        </Badge>
                    </div>
                    <h4>David Pollack</h4>
                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:davidpollack@tokeet.com'}>
                        davidpollack@tokeet.com
                    </Link>
                </div>
                <ul>
                    <li className="active">
                        <Link to="/owner-approval">
                            <Button variant="text">
                                Owner Approval <ChevronRightIcon className="arrow_active" />
                            </Button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/account-info">
                            <Button variant="text">
                                Account Info <ChevronRightIcon className="arrow_active" />
                            </Button>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <Button variant="text">
                                Automation <ChevronRightIcon className="arrow_active" />
                            </Button>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <Button variant="text">
                                Billing Info <ChevronRightIcon className="arrow_active" />
                            </Button>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="acount_content_box">
                <div className="row">
                    <div className="col-md-6 col-lg-4 col-xl-3 owner_approval_box_holder">
                        <div className="box">
                            <div className="box_content">
                                <img src={imgUser1} alt="" />
                                <h4>Yuvette Rose</h4>
                                <p>
                                    Property Owner<br />
                                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:yuvette-rose@ownercenter.com'}>
                                        yuvette-rose@ownercenter.com
                                    </Link>
                                </p>
                            </div>
                            <div className="box_ftr">
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbUpOffAltOutlinedIcon />
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbDownOutlinedIcon color='error' />
                                    </Button>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 owner_approval_box_holder">
                        <div className="box">
                            <div className="box_content">
                                <img src={imgUser2} alt="" />
                                <h4>James Smith</h4>
                                <p>
                                    Property Owner<br />
                                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:jamessmith@ownercenter.com'}>
                                        jamessmith@ownercenter.com
                                    </Link>
                                </p>
                            </div>
                            <div className="box_ftr">
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbUpOffAltOutlinedIcon />
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbDownOutlinedIcon color='error' />
                                    </Button>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 owner_approval_box_holder">
                        <div className="box">
                            <div className="box_content">
                                <img src={imgUser3} alt="" />
                                <h4>Partrick</h4>
                                <p>
                                    Property Owner<br />
                                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:partrick@ownercenter.com'}>
                                        partrick@ownercenter.com
                                    </Link>
                                </p>
                            </div>
                            <div className="box_ftr">
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbUpOffAltOutlinedIcon />
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbDownOutlinedIcon color='error' />
                                    </Button>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 owner_approval_box_holder">
                        <div className="box">
                            <div className="box_content">
                                <img src={imgUser4} alt="" />
                                <h4>Kishan Patel</h4>
                                <p>
                                    Property Owner<br />
                                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:kishanpatel@townercenter.com'}>
                                        kishanpatel@townercenter.com
                                    </Link>
                                </p>
                            </div>
                            <div className="box_ftr">
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbUpOffAltOutlinedIcon />
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbDownOutlinedIcon color='error' />
                                    </Button>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 owner_approval_box_holder">
                        <div className="box">
                            <div className="box_content">
                                <img src={imgUser5} alt="" />
                                <h4>Sanjay Patel</h4>
                                <p>
                                    Property Owner<br />
                                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:sanjay@ownercenter.com'}>
                                        sanjay@ownercenter.com
                                    </Link>
                                </p>
                            </div>
                            <div className="box_ftr">
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbUpOffAltOutlinedIcon />
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbDownOutlinedIcon color='error' />
                                    </Button>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 owner_approval_box_holder">
                        <div className="box">
                            <div className="box_content">
                                <img src={imgUser6} alt="" />
                                <h4>Madhurina</h4>
                                <p>
                                    Property Owner<br />
                                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:madhurina@ownercenter.com'}>
                                        madhurina@ownercenter.com
                                    </Link>
                                </p>
                            </div>
                            <div className="box_ftr">
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbUpOffAltOutlinedIcon />
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbDownOutlinedIcon color='error' />
                                    </Button>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 owner_approval_box_holder">
                        <div className="box">
                            <div className="box_content">
                                <img src={imgUser7} alt="" />
                                <h4>Anne Kocher</h4>
                                <p>
                                    Property Owner<br />
                                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:anne@ownercenter.com'}>
                                        anne@ownercenter.com
                                    </Link>
                                </p>
                            </div>
                            <div className="box_ftr">
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbUpOffAltOutlinedIcon />
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbDownOutlinedIcon color='error' />
                                    </Button>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 owner_approval_box_holder">
                        <div className="box">
                            <div className="box_content">
                                <img src={imgUser8} alt="" />
                                <h4>Ashok Kumar</h4>
                                <p>
                                    Property Owner<br />
                                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:ashok@townercenter.com'}>
                                        ashok@townercenter.com
                                    </Link>
                                </p>
                            </div>
                            <div className="box_ftr">
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbUpOffAltOutlinedIcon />
                                    </Button>
                                </div>
                                <div>
                                    <Button variant="text" fullWidth>
                                        <ThumbDownOutlinedIcon color='error' />
                                    </Button>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>

    </div>
  );
}

export default OwnerApprovalPage;