import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Drawer, FormControl, IconButton, InputAdornment, MenuItem, Select, Switch, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendIcon from '@mui/icons-material/Send';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import './InvoicesEditOverlay.scss';
import imgMercure from '../../../../assets/images/placeholder/mercure-hotels-logo.png';
import imgflagIndia from '../../../../assets/images/placeholder/india_flag.svg';
import imgflagCad from '../../../../assets/images/placeholder/cad.png';
import imgflagUsd from '../../../../assets/images/placeholder/usd.png';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`payment-info-tabpanel-${index}`}
        className="full_height position-relative"
        aria-labelledby={`payment-info-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className="tab_panel_holder">
            {children}
          </div>
        )}
      </div>
    );
}
function PaymentInfoTabsProps(index: number) {
    return {
        id: `payment-info-tab-${index}`,
        'aria-controls': `payment-info-tabpanel-${index}`,
    };
}

export default function InvoicesEditOverlay(props: any) {
    const [valueInvoiceDate, setInvoiceDateValue] = React.useState<Dayjs | null>(dayjs('2022-11-05'));
    const [valueDueDate, setDueDateValue] = React.useState<Dayjs | null>(dayjs('2023-01-27'));
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
    <Drawer
        anchor="right"
        open={props.open}
        onClose = {(event, reason) => {
            if (props.onClose) {
                props.onClose(event, reason);
            }
        }}
        className="invoices_edit_overlay_holder"
    >
        <Button 
            className="btn_circle_close" 
            onClick={props.onClose}
        >
            <CloseIcon />
        </Button>
        <div className="d_header">
           <div className="title">Ruben Clark</div> 
           <div className="btn_holder">
                <FormControl className="mui-dflt-select-box has_img">
                    <Select
                        defaultValue={'USD ($)'}
                    >
                        <MenuItem className='d_flx_img_menu_item' value={'INR (₹)'}>
                            <img src={imgflagIndia} alt="" /> INR
                        </MenuItem> 
                        <MenuItem className='d_flx_img_menu_item' value={'USD ($)'}>
                            <img src={imgflagUsd} alt="" /> USA
                        </MenuItem>
                        <MenuItem className='d_flx_img_menu_item' value={'CAD ($)'}>
                            <img src={imgflagCad} alt="" /> CAD
                        </MenuItem>
                    </Select>
                </FormControl>
                <Tooltip title="Delete Invoice" placement="bottom" arrow>
                    <Button variant="outlined" className="btn_mui_icon_outline text-danger">
                        <DeleteOutlineOutlinedIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Cancel Invoice" placement="bottom" arrow>
                    <Button variant="outlined" className="btn_mui_icon_outline">
                        <BlockOutlinedIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Check Invoice Price" placement="bottom" arrow>
                    <Button variant="outlined" className="btn_mui_icon_outline">
                        <PriceCheckOutlinedIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Show Invoice" placement="bottom" arrow>
                    <Button variant="outlined" className="btn_mui_icon_outline">
                        <VisibilityOffOutlinedIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Print Invoice" placement="bottom" arrow>
                    <Button variant="outlined" className="btn_mui_icon_outline">
                        <LocalPrintshopOutlinedIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Finalize Invoice" placement="bottom" arrow>
                    <Button variant="outlined" className="btn_mui_icon_outline">
                        <AssignmentTurnedInOutlinedIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Send Invoice" placement="bottom" arrow>
                    <Button
                        className="btn_mui_light_blue no_shadow text-uppercase"
                        variant="contained"
                        startIcon={<SendIcon style={{ fontSize: 20 }} />}
                    >
                        Re-send
                    </Button>
                </Tooltip>
                <Tooltip title="Update Invoice" placement="bottom" arrow>
                    <Button
                        className="btn_mui_primary no_shadow text-uppercase"
                        variant="contained"
                        startIcon={<SaveOutlinedIcon style={{ fontSize: 20 }} />}
                    >
                            Save
                    </Button>
                </Tooltip>
           </div>
        </div>
        <div className="d_content">
            <div className="row align-items-center justify-content-between">
                <div className="col-auto back_to_pev_page">
                    <Link to="#">
                        <KeyboardBackspaceIcon />
                        back to owner invoices
                    </Link>
                </div>
                <div className="col-auto flx-box">
                    <div className="switch_holder">
                        <label>Automatically send invoice</label>
                        <Switch defaultChecked className="mui_dflt_switch" />
                    </div>
                </div>
            </div>
            <div className="row payment_status_box align-items-center justify-content-between">
                <div className="col-12 col-sm-auto company_logo">
                    <img src={imgMercure} alt="" />
                </div>
                <div className="col-12 col-sm-auto">
                    {/* <label className="paid">Paid</label> */}
                    <label className="unpaid">Unpaid</label>
                </div>
            </div>
            <div className="row invoice_info_flx_box">
                <div className="col-md-4 box">
                    <h6>invoice #</h6>
                    <h1>#1604583060172</h1>
                    <div className="input_box">
                        <label>Invoice Date</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={valueInvoiceDate}
                                className="mui_dflt_outlined_input"
                                onChange={(newValue) => {
                                    setInvoiceDateValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="D - MMM - YYYY"
                            />
                        </LocalizationProvider>
                        
                    </div>
                    <div className="input_box">
                        <label>Due Date</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={valueDueDate}
                                className="mui_dflt_outlined_input"
                                onChange={(newValue) => {
                                    setDueDateValue(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="D - MMM - YYYY"
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="col-md-4 box">
                    <h6>Bill to</h6>
                    <p>
                        <strong>Cedarwood resort</strong> <br />
                        Address 123 666 2988 <br />
                        City City, State / Province / <br />Region 54000 <br />
                        Afghanistan
                        <br /><br />
                        +81-978789798709 <br />
                        emptykanza2@test.com
                    </p>
                </div>
                <div className="col-md-4 box">
                    <h6>Bill from</h6>
                    <p>
                        <strong>Anne Kocher</strong> <br />
                        Address 123 666 2988 <br />
                        City City, State / Province / <br />Region 54000 <br />
                        Afghanistan
                        <br /><br />
                        +81-978789798709 <br />
                        emptykanza2@test.com
                    </p>
                </div>
            </div>
            
            {/* Line Items */}
            <div className="row item_tbl_title_box">
                <div className="col-md-7">
                    <div className="row">
                        <div className="col-md-5">Item Description</div>
                        <div className="col-md-7">Rental</div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <div className="col-md-6">Nights</div>
                        <div className="col-md-6">Guests</div>
                    </div>
                </div>
                <div className="col-md-2">Payable</div>
            </div>

            <div className="row item_tbl_row">
                <div className="col-md-7">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="input_box">
                                <label>Item Description</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="Food Items" 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input"
                                />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="input_box">
                                <label>Rental</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="1021 SW Klickitat Way, #102" 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <div className="col-6">
                            <div className="input_box">
                                <label>Nights</label>
                                <TextField 
                                    variant="outlined"
                                    defaultValue="" 
                                    fullWidth
                                    type="number" 
                                    size="small"
                                    className="mui_dflt_outlined_input"
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="input_box">
                                <label>Guests</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="" 
                                    type="number" 
                                    size="small"
                                    className="mui_dflt_outlined_input"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="input_box">
                        <label>Payable</label>
                        <TextField 
                            variant="outlined"
                            fullWidth
                            defaultValue="345" 
                            type="number" 
                            size="small"
                            className="mui_dflt_outlined_input has_l_icon"
                            InputProps={{
                                startAdornment: 
                                <InputAdornment position="start">
                                    <AttachMoneyIcon fontSize='small' />
                                </InputAdornment>,
                            }}
                        />
                    </div>
                </div>
                {/* Delete Row */}
                <Tooltip title="Remove this item" placement="right" arrow>
                    <IconButton className="btn_delete_item">
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </div>
            
            <div className="add_item_btn_holder">
                <Button
                    className="btn_mui_primary no_shadow text-uppercase"
                    variant="contained"
                    startIcon={<AddIcon style={{ fontSize: 20 }} />}
                >
                    Add line item
                </Button>
            </div>
            
            {/* Expenses */}
            <div className="row item_tbl_title_box">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">EXPENSE DATE</div>
                                <div className="col-md-6">DUE DATE</div>
                            </div>
                        </div>
                        <div className="col-md-5">Rental</div>
                    </div>
                </div>
                <div className="col-md-2">CATEGORY</div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-4">AMOUNT</div>
                        <div className="col-md-4">METHOD</div>
                        <div className="col-md-4">STATUS</div>
                    </div>
                </div>
            </div>

            <div className="row item_tbl_row">
                <div className="col-xl-6">
                    <div className="row">   
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input_box">
                                        <label>EXPENSE DATE</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={valueInvoiceDate}
                                                className="mui_dflt_outlined_input full-width"
                                                onChange={(newValue) => {
                                                    setInvoiceDateValue(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                                inputFormat="D - MMM - YYYY"
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box">
                                        <label>Due Date</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={valueDueDate}
                                                className="mui_dflt_outlined_input full-width"
                                                onChange={(newValue) => {
                                                    setDueDateValue(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                                inputFormat="D - MMM - YYYY"
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="input_box">
                                <label>Rental</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="1021 SW Klickitat Way, #102" 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-xl-2">
                    <div className="input_box">
                        <label>CATEGORY</label>
                        <FormControl className="mui-dflt-select-box" fullWidth>
                            <Select
                                defaultValue={'Gardening'}
                            >
                                <MenuItem value={'Gardening'}>
                                    Gardening
                                </MenuItem> 
                                <MenuItem value={'Category 02'}>
                                    Category 02
                                </MenuItem> 
                                <MenuItem value={'Category 03'}>
                                    Category 03
                                </MenuItem> 
                                <MenuItem value={'Category 04'}>
                                    Category 04
                                </MenuItem> 
                                <MenuItem value={'Category 05'}>
                                    Category 05
                                </MenuItem> 
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="col-md-8 col-xl-4">
                    <div className="row">
                        <div className="col-6 col-md-4">
                            <div className="input_box">
                                <label>AMOUNT</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="345" 
                                    type="number" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_l_icon"
                                    InputProps={{
                                        startAdornment: 
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon fontSize='small' />
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="input_box">
                                <label>METHOD</label>
                                <FormControl className="mui-dflt-select-box" fullWidth>
                                    <Select
                                        defaultValue={'Cash'}
                                    >
                                        <MenuItem value={'Cash'}>
                                            Cash
                                        </MenuItem> 
                                        <MenuItem value={'Check'}>
                                            Check
                                        </MenuItem> 
                                        <MenuItem value={'Bank Card'}>
                                            Bank Card
                                        </MenuItem> 
                                        <MenuItem value={'Paypal'}>
                                            Paypal
                                        </MenuItem> 
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="input_box">
                                <label>STATUS</label>
                                <FormControl className="mui-dflt-select-box" fullWidth>
                                    <Select
                                        defaultValue={'Open'}
                                    >
                                        <MenuItem value={'Open'}>
                                            Open
                                        </MenuItem> 
                                        <MenuItem value={'Status 01'}>
                                            Status 01
                                        </MenuItem> 
                                        <MenuItem value={'Status 02'}>
                                            Status 02
                                        </MenuItem> 
                                        <MenuItem value={'Status 03'}>
                                            Status 03
                                        </MenuItem> 
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Delete Row */}
                <Tooltip title="Remove this item" placement="right" arrow>
                    <IconButton className="btn_delete_item">
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </div>
            
            <div className="add_item_btn_holder">
                <Button
                    className="btn_mui_primary no_shadow text-uppercase"
                    variant="contained"
                    startIcon={<AddIcon style={{ fontSize: 20 }} />}
                >
                    Add expenses
                </Button>
            </div>

            <div className="messages">
                <h6>Message</h6>
                <p>
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                    Excepteur sint occaecat cupidatat non proident
                </p>
            </div>

            <div className="row align-items-center justify-content-between">
                <div className="col-md-7 col-lg-6 tabs_holder">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        className="payment_tabs"
                        variant="scrollable"
                        scrollButtons={false}
                    >
                        <Tab label="Payment Terms" {...PaymentInfoTabsProps(0)} />
                        <Tab label="Payment Instructions" {...PaymentInfoTabsProps(2)} />
                        <Tab label="Notes" {...PaymentInfoTabsProps(3)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <TextField 
                            variant="outlined"
                            multiline
                            fullWidth
                            placeholder="Payment Terms(Visible to guest on invoice)" 
                            type="text" 
                            size="small"
                            rows={5}
                            className="mui_dflt_outlined_input text_area"
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TextField 
                            variant="outlined"
                            multiline
                            fullWidth
                            placeholder="Payment Instructions(Visible to guest on invoice)" 
                            type="text" 
                            size="small"
                            rows={5}
                            className="mui_dflt_outlined_input text_area"
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TextField 
                            variant="outlined"
                            multiline
                            fullWidth
                            placeholder="Notes(Visible to guest on invoice)" 
                            type="text" 
                            size="small"
                            rows={5}
                            className="mui_dflt_outlined_input text_area"
                        />
                    </TabPanel>
                </div>
                <div className="col-md-4 col-xl-3 invoice_summary">
                    <h4>Invoice Summary</h4>
                    <div className="tbl_row">
                        <div>Subtotal</div>
                        <div><h3>$0.00</h3></div>
                    </div>
                    <div className="tbl_row">
                        <div>Total Payout</div>
                        <div><h2>$ 1,380</h2></div>
                    </div>
                    <div className="tbl_row">
                        <Link to="#"><AddIcon /> Add Tax</Link>
                    </div>
                    <div className="input_box">
                        <label>Select Gateway</label>
                        <FormControl className="mui-dflt-select-box" fullWidth>
                            <Select
                                defaultValue={'Paypal'}
                            >
                                <MenuItem value={'Stripe'}>
                                    Stripe
                                </MenuItem> 
                                <MenuItem value={'Paytm Business'}>
                                    Paytm Business
                                </MenuItem> 
                                <MenuItem value={'Payline'}>
                                    Payline
                                </MenuItem> 
                                <MenuItem value={'Paypal'}>
                                    Paypal
                                </MenuItem> 
                            </Select>
                        </FormControl>
                    </div>
                    <div className="btn_holder">
                        <Button
                            className="btn_mui_primary no_shadow text-uppercase"
                            variant="contained"
                            fullWidth
                            startIcon={<CreditCardIcon style={{ fontSize: 20 }} />}
                        >
                            Pay with card
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    </Drawer>
    );
}
