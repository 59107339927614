import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './ApprovedPopup.scss';

export default function ApprovedPopup(props: any) {
  return (
    <Dialog
        maxWidth='xs'
        fullWidth
        className="owner_approved_popup"
        open={props.open}
        onClose = {(event, reason) => {
            if (props.onClose) {
                props.onClose(event, reason);
            }
        }}
      >
        <DialogTitle className="dialog_title_box">
            <div className="title_holder">
                <h4>Approved</h4>
            </div>
            <IconButton onClick={props.onClose} className="close_btn">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content_box">
            <p className="text-center">
                You have successfully <br />
                approved <strong>Yuvette Rose’s</strong> Registration
            </p>
        </DialogContent>
        <DialogActions className="dialog_footer_box">
            <div className="row justify-content-center">
                <div className="col-auto">
                    <Button variant='outlined' className="btn_mui_light_blue text-uppercase" onClick={props.onClose}>OK</Button>
                </div>
            </div>
        </DialogActions>
    </Dialog>
  );
}