import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Badge, Button, FormControl, IconButton, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import './AccountInfoPage.scss';
import imageProfile from '../../../assets/images/placeholder/profile.jpeg';
import imgflagIndia from '../../../assets/images/placeholder/india_flag.svg';
import imgflagCad from '../../../assets/images/placeholder/cad.png';
import imgflagUsd from '../../../assets/images/placeholder/usd.png';

function AccountInfoPage(){

  return (
    <div className="account-info-page page_holder">
        <div className="header sticky-top has_searh_box">
            <TextField 
                variant="outlined"
                placeholder="Search for Inquries, Invoices & Guests.." 
                type="search" 
                size="small"
                className="mui_dflt_outlined_input has_l_icon search_header"
                InputProps={{
                    startAdornment: 
                    <InputAdornment position="start">
                        <IconButton size="small">
                            <SearchOutlinedIcon />
                        </IconButton>
                    </InputAdornment>,
                }}
            />
        </div>
        
        <div className="content_holder">
            <div className="account_sidebar_menus">
                <div className="profile_box">
                    <div className="profile">
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <IconButton className="edit_profile" size="small">
                                    <ModeEditOutlineOutlinedIcon sx={{ fontSize: 16, color: '#BDC4CB' }} />
                                </IconButton>
                            }
                        >
                            <Avatar src={imageProfile} alt="" sx={{ width: 75, height: 75 }} />
                        </Badge>
                    </div>
                    <h4>David Pollack</h4>
                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:davidpollack@tokeet.com'}>
                        davidpollack@tokeet.com
                    </Link>
                </div>
                <ul>
                    <li>
                        <Link to="/owner-approval">
                            <Button variant="text">
                                Owner Approval <ChevronRightIcon className="arrow_active" />
                            </Button>
                        </Link>
                    </li>
                    <li className="active">
                        <Link to="/account-info">
                            <Button variant="text">
                                Account Info <ChevronRightIcon className="arrow_active" />
                            </Button>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <Button variant="text">
                                Automation <ChevronRightIcon className="arrow_active" />
                            </Button>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <Button variant="text">
                                Billing Info <ChevronRightIcon className="arrow_active" />
                            </Button>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="acount_content_box">
                <div className="container-box">
                    <div className="row">
                        <div className="col-md-6 form_holder">
                            <div className="input_box">
                                <label>Name <span className="text-danger">*</span></label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    placeholder=""
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input" 
                                    required
                                    defaultValue="David Pollack"
                                />
                            </div>
                            <div className="input_box">
                                <label>Merchant Name</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    placeholder=""
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input" 
                                    defaultValue="New Villa"
                                />
                            </div>
                            <div className="input_box">
                                <label>Email <span className="text-danger">*</span></label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    placeholder=""
                                    type="email" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon" 
                                    defaultValue="davidpollack@tokeet.com"
                                    required
                                    InputProps={{
                                        endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton size="small">
                                                <LockOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />
                            </div>
                            <div className="input_box">
                                <label>Password</label>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            placeholder=""
                                            type="password" 
                                            size="small"
                                            className="mui_dflt_outlined_input" 
                                            defaultValue="12345678"
                                        />
                                    </div>
                                    <div className="col-xl-6 mt-2 mt-xl-0">
                                        <Button
                                            fullWidth
                                            className="btn_mui_dflt_outline text-uppercase clr_primary"
                                            variant="outlined"
                                        >
                                            Change Password
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 input_box">
                                    <label>Default Currency <span className="text-danger">*</span></label>
                                    <FormControl className="mui-dflt-select-box has_img" fullWidth>
                                        <Select
                                            defaultValue={'INR (₹)'}
                                        >
                                            <MenuItem className='d_flx_img_menu_item' value={'INR (₹)'}>
                                                <img src={imgflagIndia} alt="" /> INR (₹)
                                            </MenuItem> 
                                            <MenuItem className='d_flx_img_menu_item' value={'USD ($)'}>
                                                <img src={imgflagUsd} alt="" /> USD ($)
                                            </MenuItem>
                                            <MenuItem className='d_flx_img_menu_item' value={'CAD ($)'}>
                                                <img src={imgflagCad} alt="" /> CAD ($)
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-xl-6 input_box">
                                    <label>Phone <span className="text-danger">*</span></label>
                                    <TextField 
                                        variant="outlined"
                                        fullWidth
                                        type="email" 
                                        size="small"
                                        className="mui_dflt_outlined_input has_l_icon" 
                                        defaultValue="749 - 459 - 85009"
                                        InputProps={{
                                            startAdornment: 
                                            <InputAdornment position="end">
                                                <img src={imgflagIndia} height="18" alt="" />
                                                <IconButton size="small">
                                                    <ExpandMoreOutlinedIcon />
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 reg_ulr_holder">
                            <div className="input_box">
                                <label>Registration URL</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    placeholder=""
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon" 
                                    defaultValue="https://rentalowner.net/owner/registration"
                                    required
                                    InputProps={{
                                        endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton size="small">
                                                <ContentCopyOutlinedIcon className="clr_primary" />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  );
}

export default AccountInfoPage;