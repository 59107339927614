import { Align } from 'chart.js';

export const getMonthNameLabels = () => {
  return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
};

export const generateRandomData = (count = 12, min: number, max: number) => {
  const data = [];
  for (let i = 0; i < count; i++) {
    data.push(Math.floor(Math.random() * (max - min + 1)) + min);
  }
  return data;
};

export const legendPluginConfig = {
  display: true,
  align: 'end' as Align,
  labels: {
    boxHeight: 1,
    boxWidth: 25,
    color: '#6F7780',
    font: {
      family: 'Nunito Sans',
      size: 11,
      weight: 'bold',
    },
  },
};
