import React from 'react';
import { Link } from 'react-router-dom';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ApprovedPopup from '../pages/ownerApproval/ApprovedPopup/ApprovedPopup';
import InvoicesEditOverlay from '../pages/invoices/InvoicesEditOverlay/InvoicesEditOverlay';
import CreateInvoicesPopup from '../pages/invoices/CreateInvoicesPopup/CreateInvoicesPopup';
import CreateAutomaticInvoicesPopup from '../pages/invoices/CreateAutomaticInvoicesPopup/CreateAutomaticInvoicesPopup';

function SitemapPage(){
    // Owner Approved Popup
    const [openApprovedPopup, setApprovedPopup] = React.useState(false);
    const handleClickApprovedPopupOpen = () => {
        setApprovedPopup(true);
    };
    const handleApprovedPopupClose = () => {
        setApprovedPopup(false);
    };

    // Invoices Edit Overlay
    const [InvoicesEditOverlayOpen, setInvoicesEditOverlayOpen] = React.useState(false);
    const openInvoicesEditOverlay = (newOpen: boolean) => () => {
        setInvoicesEditOverlayOpen(newOpen);
    };

    // Create Invoices Popup
    const [openCreateInvoicesPopup, setCreateInvoicesPopup] = React.useState(false);
    const handleClickCreateInvoicesPopupOpen = () => {
        setCreateInvoicesPopup(true);
    };
    const handleCreateInvoicesPopupClose = () => {
        setCreateInvoicesPopup(false);
    };

    // Create Automatic Invoices Popup
    const [openCreateAutomaticInvoicesPopup, setCreateAutomaticInvoicesPopup] = React.useState(false);
    const handleClickCreateAutomaticInvoicesPopupOpen = () => {
        setCreateAutomaticInvoicesPopup(true);
    };
    const handleCreateAutomaticInvoicesPopupClose = () => {
        setCreateAutomaticInvoicesPopup(false);
    };

  return (
    <div className="page_holder">
        <div className="header sticky-top has_searh_box">
            <TextField 
                variant="outlined"
                placeholder="Search" 
                type="search" 
                size="small"
                className="mui_dflt_outlined_input has_l_icon search_header"
                InputProps={{
                    startAdornment: 
                    <InputAdornment position="start">
                        <IconButton size="small">
                            <SearchOutlinedIcon />
                        </IconButton>
                    </InputAdornment>,
                }}
            />
        </div>
        <div className="content_holder">
            <h4>OC-793: Design PM View Screens</h4>
            <Link to="/dashboard" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Dashboard Page</Button>
            </Link>
            <br />
            <Link to="/owner-invoices" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Invoices Page</Button>
            </Link>
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={openInvoicesEditOverlay(true)}>
                <Button variant="contained" className="btn_mui_primary">Invoices Edit Overlay</Button>
            </Link>
            <InvoicesEditOverlay 
                open={InvoicesEditOverlayOpen}
                onClose={openInvoicesEditOverlay(false)}
            />
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={handleClickCreateInvoicesPopupOpen}>
                <Button variant="contained" className="btn_mui_primary">Create Invoices Popup</Button>
            </Link>
            <CreateInvoicesPopup 
                onClose={handleCreateInvoicesPopupClose}
                open={openCreateInvoicesPopup}
            />
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={handleClickCreateAutomaticInvoicesPopupOpen}>
                <Button variant="contained" className="btn_mui_primary">Create Automatic Invoices Popup</Button>
            </Link>
            <CreateAutomaticInvoicesPopup 
                onClose={handleCreateAutomaticInvoicesPopupClose}
                open={openCreateAutomaticInvoicesPopup}
            />
            <br />
            <Link to="/owner-statements" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Statements Page</Button>
            </Link>
            <br />
            <Link to="/expenses" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Expenses Page</Button>
            </Link>
            <br />
            <Link to="/login" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Login Page</Button>
            </Link>
            <Link to="/signup" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Signup Page</Button>
            </Link>
            <br />
            <Link to="/account-info" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Account Info Page/PM Share Registration URL</Button>
            </Link>
            <Link to="/owner-approval" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Owner Approval Page</Button>
            </Link>
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={handleClickApprovedPopupOpen}>
                <Button variant="contained" className="btn_mui_primary">PM Permissions Approved Popup</Button>
            </Link>
            <ApprovedPopup 
                onClose={handleApprovedPopupClose}
                open={openApprovedPopup}
            />
        </div>
    </div>
  );
}

export default SitemapPage;