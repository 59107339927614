import React, { memo } from 'react';
import { Chart as ReactChart, ChartProps as ReactChartProps } from 'react-chartjs-2';
import { Chart as ChartJS, ChartOptions, registerables } from 'chart.js';
ChartJS.register(...registerables);

export type ChartProps = ReactChartProps;

export const Chart = memo((props: ChartProps) => {
  const { type, data, options: _options, ...rest } = props;

  const options: ChartOptions = {
    ..._options,
    scales: {
      x: {
        grid: {
          lineWidth: 1,
          color: '#E9EBF1',
        },
        ticks: {
          color: '#6F7780',
          font: {
            family: 'Nunito Sans',
            size: 11,
            weight: 'bold',
          },
        },
        ..._options?.scales?.x,
      },
      y: {
        grid: {
          color: '#E9EBF1',
          lineWidth: 1,
        },
        ticks: {
          color: '#6F7780',
          font: {
            family: 'Nunito Sans',
            size: 11,
            weight: 'bold',
          },
        },
        ..._options?.scales?.y,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      ..._options?.plugins,
    },
  };

  return <ReactChart className="chart" type={type || 'line'} data={data} options={options} {...rest} />;
});
