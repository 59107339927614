import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Avatar, Tooltip, Drawer, IconButton, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import './SideBar.scss';
import imageLogo from '../../../assets/images/logo.png';
import imageProfile from '../../../assets/images/placeholder/profile.jpeg';

function SideBar(){
    // Getting mobile devices
    const [userIsDesktop, setUserIsDesktop] = useState(true);
    useEffect(() => {
    window.innerWidth > 767 ? setUserIsDesktop(true) : setUserIsDesktop(false);
    }, [userIsDesktop]);
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    return (
        <>
        {userIsDesktop && (
           <div className='sidebar_holder'>
                <div className="sticky-top logo_holder">
                    <Link to="/">
                        <img src={imageLogo} alt="Owner Center" title="Owner Center" />
                    </Link>
                </div>
                <div className="nav_holder">
                    <NavLink to="/dashboard" end className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Dashboard" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-dashboard" style={{fontSize: 20}}></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/owners" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Owners" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-guests" style={{fontSize: 16}}></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/owner-statements" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Owner Statements" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-statements"></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/owner-invoices" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Owner Invoices" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-invoices"></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/payout-rules" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Payout Rules" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-calc"></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/expenses" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Expenses" placement="right" arrow>
                            <Button variant="text">
                                <AccountBalanceWalletIcon sx={{fontSize: 26}} />
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/portal" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Portal" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-glob"></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                </div>
                <div className="nav_bottom_fxd">
                    <Link to="#">
                        <Tooltip title="Notifications" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-notification"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="#">
                        {/* <Avatar  sx={{ width: 45, height: 45, bgcolor: '#02AB9D' }}>H</Avatar> */}
                        <Avatar alt="David Pollack" src={imageProfile} sx={{ width: 45, height: 45, bgcolor: '#02AB9D' }} />
                    </Link>
                    <Link to="#">
                        <Tooltip title="Logout" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-logout"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                </div>
           </div> 
        )}
        {/* ====Mobile version */}
        {!userIsDesktop && (
            <div className="mobile_nav_holder">
                <IconButton className="toggle_btn" onClick={toggleDrawer(true)}>
                    <MenuIcon sx={{ fontSize: 25, color: '#000' }} />
                </IconButton>
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={toggleDrawer(false)}
                    className="mobile_menu_drawer"
                >
                    <div className="nav_header">
                        <div className="profile_box">
                            <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <IconButton>
                                    <CameraAltOutlinedIcon sx={{ fontSize: 12, color: '#03A99D' }} ></CameraAltOutlinedIcon>
                                </IconButton>
                            }
                            >
                                <Avatar alt="David Pollack" src={imageProfile} sx={{ width: 56, height: 56, bgcolor: '#02AB9D' }}/>
                            </Badge>
                        </div>
                        <div className="profile_info">
                            <h4 className="name">David Pollack</h4>
                            <Link className="email" to="#" onClick={() => window.location.href = 'mailto:davidpollack@tokeet.com'}>
                                davidpollack@tokeet.com
                            </Link>
                        </div>
                    </div>
                    <div className="navbox">
                        <NavLink to="/dashboard" end className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-dashboard" style={{fontSize: 20}}></i>
                            <span>Dashboard</span>
                        </NavLink>
                        <NavLink to="/owners" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-guests" style={{fontSize: 16}}></i>
                            <span>Owners</span>
                        </NavLink>
                        <NavLink to="/owner-statements" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-statements"></i>
                            <span>Owner Statements</span>
                        </NavLink>
                        <NavLink to="/owner-invoices" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-invoices"></i>
                            <span>Owner Invoices</span>
                        </NavLink>
                        <NavLink to="/payout-rules" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-calc"></i>
                            <span>Payout Rules</span>
                        </NavLink>
                        <NavLink to="/expenses" className={({ isActive }) => 
                                (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <AccountBalanceWalletIcon sx={{fontSize: 26}} />
                            <span>Expenses</span>
                        </NavLink>
                        <NavLink to="/portal" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-glob"></i>
                            <span>Portal</span>
                        </NavLink>

                        <NavLink to="#" className="logout">
                            <i className="icon icon-logout"></i>
                            <span>Logout</span>
                        </NavLink>
                    </div>
                </Drawer>
            </div>
        )}
        </>
    );
}

export default SideBar;

