import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";

import SideBar from './app/components/sidebar/SideBar';
import SiteMap from './app/sitemap/SitemapPage';
import InvoicesPage from "./app/pages/invoices/InvoicesPage";
import StatementsPage from "./app/pages/statements/StatementsPage";
import LoginPage from './app/pages/auth/LoginPage/LoginPage';
import SignupPage from './app/pages/auth/SignupPage/SignupPage';
import AccountInfoPage from './app/pages/accountInfo/AccountInfoPage';
import OwnerApprovalPage from './app/pages/ownerApproval/OwnerApprovalPage';
import DashboardPage from './app/pages/dashboard/DashboardPage';
import ExpensesPage from './app/pages/expenses/ExpensesPage';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#02AB9D',
    },
  },
  typography: {
    "fontFamily": `'Nunito Sans', sans-serif`,
   },
});

function App() {
  const [isFetching, setIsFetching] = useState(true); 
  useEffect(() => {
      setTimeout(function () {
      setIsFetching(false); 
      }, 2500);
  }, []);

  if (isFetching) {
    return (        
      <div className="loading-screen">
        <GridLoader color="#02ab9d" size={20} />
      </div>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>   
        <SideBar />
        <Routes>
          <Route path="/" element={<SiteMap />} />
          <Route path="/sitemap" element={<SiteMap />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/owner-invoices" element={<InvoicesPage />} />
          <Route path="/owner-statements" element={<StatementsPage />} />
          <Route path="/expenses" element={<ExpensesPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/account-info" element={<AccountInfoPage />} />
          <Route path="/owner-approval" element={<OwnerApprovalPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
